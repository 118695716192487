.custom_emptySlot:hover {
    background-color: red;
    color: white;
    cursor: pointer;
}

.custom_emptySlot {
    color: transparent;
}

.custom_emptySlotDisabled {
    color: transparent;
}

.mainMenu_button {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
    border-radius: 10px 0 0 10px;
    margin-bottom: 5px;
    width: 90%;
    margin-left: 15%;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mainMenu_button:hover {
    font-weight: bold;
    border-radius: 40px 0px 0px 40px;
    /* border: 1px solid #add8e6; */
}

.mainMenu_buttonSelected {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #00008b;
    color: white;
    font-weight: bold;
    border-radius: 40px 0 0 40px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 90%;
    margin-left: 15%;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.topSection_leftButton {
    background-color: white;
    color: black;
    padding: 5px 20px;
    font-size: 1.5rem;
    border-radius: 25px 0 0 25px;
    border-right: 1px solid black;
    cursor: pointer;
}

.topSection_leftButton:hover {
    background-color: grey;
    color: white;
    padding: 5px 20px;
    font-size: 1.5rem;
    border-radius: 25px 0 0 25px;
    border-right: 1px solid black;
    cursor: pointer;
}

.topSection_leftButton:active {
    background-color: #00008b;
    color: white;
    padding: 5px 20px;
    font-size: 1.5rem;
    border-radius: 25px 0 0 25px;
    border-right: 1px solid black;
    cursor: pointer;
}

.topSection_rightButton {
    background-color: white;
    color: black;
    padding: 5px 20px;
    font-size: 1.5rem;
    border-radius: 0 25px 25px 0;
    cursor: pointer;
}

.topSection_rightButton:hover {
    background-color: gray;
    color: white;
    padding: 5px 20px;
    font-size: 1.5rem;
    border-radius: 0 25px 25px 0;
    cursor: pointer;
}

.topSection_rightButton:active {
    background-color: #00008b;
    color: white;
    padding: 5px 20px;
    font-size: 1.5rem;
    border-radius: 0 25px 25px 0;
    cursor: pointer;
}

.scrollBar_upButton {
    background-color: white;
    color: black;
    padding: 5px 17px;
    font-size: 1.5rem;
    border-radius: 25px 25px 0 0;
    border-bottom: 1px solid black;
    cursor: pointer;
    width: fit-content;
}

.scrollBar_upButton:hover {
    background-color: grey;
    color: white;
    padding: 5px 17px;
    font-size: 1.5rem;
    border-radius: 25px 25px 0 0;
    border-bottom: 1px solid black;
    cursor: pointer;
    width: fit-content;
}

.scrollBar_upButton:active {
    background-color: #00008b;
    color: white;
    padding: 5px 17px;
    font-size: 1.5rem;
    border-radius: 25px 25px 0 0;
    border-bottom: 1px solid black;
    cursor: pointer;
    width: fit-content;
}

.scrollBar_downButton {
    background-color: white;
    color: black;
    padding: 5px 17px;
    font-size: 1.5rem;
    border-radius: 0 0 25px 25px;
    cursor: pointer;
    width: fit-content;
}

.scrollBar_downButton:hover {
    background-color: grey;
    color: white;
    padding: 5px 17px;
    font-size: 1.5rem;
    border-radius: 0 0 25px 25px;
    cursor: pointer;
    width: fit-content;
}

.scrollBar_downButton:active {
    background-color: #00008b;
    color: white;
    padding: 5px 17px;
    font-size: 1.5rem;
    border-radius: 0 0 25px 25px;
    cursor: pointer;
    width: fit-content;
}

::-webkit-scrollbar {
    display: none;
}
